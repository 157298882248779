<template>
    <div class="MemberTrade" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: left"
                v-model="detail"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="detail.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item>
                            <el-button size="small" type="primary" @click="handleQuery">查询</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-form @submit.native.prevent :inline="true" style="text-align: left" size="small" label-width="85px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="会员名称">
                            <el-input v-model="form.name" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="会员手机号">
                            <el-input v-model="form.mobile" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="时间">
                            <span>{{ getTime() }}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <div>
            <el-card shadow="never" style="margin-top: 8px; text-align: left; height: 390px; overflow: auto">
                <el-row :gutter="20" v-for="item in tradeLists" :key="item.type">
                    <el-col :span="20" v-if="item.type == 1">
                        <div class="grid-content bg-purple">
                            <p style="justify-content: center">{{ item.type == 1 ? '交易单据' : '退货单据' }}</p>
                            <p>
                                <span>门店名称:{{ item.deptName }}</span>
                                <span>日期:{{ item.time }}</span>
                                <span>单号:{{ item.tradeNo }}</span>
                            </p>
                            <p>
                                <span>收银员:{{ item.cashierName }}</span>
                                <span>总金额:{{ item.goodsMoney | money }}</span>
                                <span>优惠:{{ item.goodsFavour | money }}</span>
                            </p>
                            <p>
                                <span>整单优惠:{{ item.tradeFavour | money }}</span>
                                <span>抹零:{{ item.floor | money }}</span>
                                <span>合计:{{ item.money | money }}</span>
                            </p>
                            <p>
                                <span>已退金额:{{ item.refundMoney | money }}</span>
                                <span>剩余金额:{{ (item.money - item.refundMoney) | money }}</span>
                                <span>会员折扣:{{ item.memberDiscount }}</span>
                                <span>会员等级:{{ item.memberLevel }}</span>
                            </p>
                            <div v-for="value in item.pays" :key="value.payName">
                                <div>
                                    {{ value.payName }}:<span>{{ value.money | money }}</span>
                                </div>
                            </div>
                            <el-table
                                border
                                stripe
                                style="width: 100%; margin-top: 30px; height: 120px"
                                :data="item.details"
                                size="mini"
                                :highlight-current-row="true"
                                max-height="520"
                            >
                                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />

                                <el-table-column label="商品名称" width="160" prop="skuName" />
                                <el-table-column label="单位" width="180" prop="unit" />
                                <el-table-column label="数量" width="160" prop="count" />
                                <el-table-column label="单价" width="160" prop="price" :formatter="formatTableMoney" />
                                <el-table-column
                                    label="小计"
                                    width="160"
                                    prop="goodsMoney"
                                    :formatter="formatTableMoney"
                                />
                                <el-table-column
                                    label="优惠"
                                    width="160"
                                    prop="favourMoney"
                                    :formatter="formatTableMoney"
                                />
                            </el-table>
                        </div>
                    </el-col>
                    <el-col :span="20" v-if="item.type == 2">
                        <div class="grid-content bg-purple">
                            <p style="justify-content: center">{{ item.type == 1 ? '交易单据' : '退货单据' }}</p>
                            <p>
                                <span>门店名称:{{ item.deptName }}</span>
                                <span>日期:{{ item.time }}</span>
                                <span>退货单号:{{ item.refundNo }}</span>
                            </p>
                            <p>
                                <span>退货操作人:{{ item.refundCashierName }}</span>
                                <span>交易单号:{{ item.tradeNo }}</span>
                                <span>收银员:{{ item.cashierName }}</span>
                            </p>
                            <p>
                                <span>应退总额:{{ item.shouldRefundMoney | money }}</span>
                                <span>实退金额:{{ item.money | money }}</span>
                                <span>会员折扣:{{ item.memberDiscount }}</span>
                            </p>
                            <p>
                                <span>会员等级:{{ item.memberLevel }}</span>
                            </p>
                            <div v-for="value in item.pays" :key="value.payName">
                                <div>
                                    {{ value.payName }}:<span>{{ value.money | money }}</span>
                                </div>
                            </div>
                            <el-table
                                border
                                stripe
                                style="width: 100%; margin-top: 30px; height: 120px"
                                :data="item.details"
                                size="mini"
                                :highlight-current-row="true"
                                max-height="520"
                            >
                                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />

                                <el-table-column label="商品名称" width="160" prop="skuName" />
                                <el-table-column label="单位" width="180" prop="unit" />
                                <el-table-column label="数量" width="160" prop="count" />
                                <el-table-column label="单价" width="160" prop="price" :formatter="formatTableMoney" />
                                <el-table-column
                                    label="小计"
                                    width="160"
                                    prop="goodsMoney"
                                    :formatter="formatTableMoney"
                                />
                                <el-table-column
                                    label="优惠"
                                    width="160"
                                    prop="favourMoney"
                                    :formatter="formatTableMoney"
                                />
                                <el-table-column
                                    label="应退金额"
                                    width="160"
                                    prop="needRefund"
                                    :formatter="formatTableMoney"
                                />
                            </el-table>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
        </div>
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'MemberTrade',
    props: ['form'],
    data() {
        return {
            detail: {
                memberCode: this.form.code,
                deptGroupCode: this.form.nowDeptGroupCode || '',
                deptCode: this.form.nowDeptCode || '',
            },
            depts: [],
            url: {
                page: '/report/cash/memberCashAndRefund',
            },
            tradeLists: [],
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.depts = rst;
        });
        this.handleQuery();
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: { deptCode: _this.detail.deptCode, memberCode: _this.detail.memberCode } };
            Util.queryTable(_this, _this.url.page, _params, (data) => {
                _this.tradeLists = data.data || [];
            });
        },
        getTime() {
            const nowDate = new Date();
            nowDate.setDate(nowDate.getDate() - 60);
            const startTime = this.formatDate(nowDate);
            const endTime = this.formatDate(new Date());
            return startTime + ' 至 ' + endTime;
        },
        formatTableMoney(row, column, money) {
            return this.fmtMoney(money);
        },
    },
};
</script>

<style scoped>
.MemberTrade .el-form-item {
    margin-bottom: 0;
}

.el-row {
    margin-bottom: 30px;
}

.el-row:last-child {
    margin-bottom: 0;
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    background: #e8faf3;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.grid-content div div {
    padding-left: 80px;
}

.grid-content p {
    display: flex;
    justify-content: space-between;
    padding: 0 80px;
}

.grid-content p span {
    display: inline-block;
    width: 33%;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}
</style>
